/* Fonts */

@font-face {
  font-weight: 700;
  font-family: 'Apercu';
  src: url('../static/fonts/33F1A3_0_0.70b1727.woff2');
}

@font-face {
  font-weight: 500;
  font-family: 'Apercu';
  src: url('../static/fonts/33F1A3_2_0.ef950f0.woff2');
}

@font-face {
  font-weight: 300;
  font-family: 'Akkurat';
  src: url('../static/fonts/akkurat-light.otf');
}

@font-face {
  font-weight: 500;
  font-family: 'Akkurat';
  src: url('../static/fonts/akkurat-regular.otf');
}

@font-face {
  font-weight: 800;
  font-family: 'Apercu';
  src: url('../static/fonts/apercu-bold-pro.woff');
}

@font-face {
  font-weight: 100;
  font-family: 'Apercu';
  src: url('../static/fonts/akkurat-light.otf');
}

@font-face {
  font-weight: 400;
  font-family: 'Apercu';
  src: url('../static/fonts/akkurat-regular.otf');
}

@font-face {
  font-weight: 600;
  font-family: 'Apercu';
  src: url('../static/fonts/apercu-medium-pro.woff');
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Apercu', sans-serif;
}

/* About */

#about {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  max-width: 70rem;

  height: 80vh;
  margin: auto;
  padding: 2rem;
}

.name {
  font-size: 5vw;
  line-height: 6vw;
}

.disclaimer {
  margin-bottom: 2.5vw;

  /* font-style: italic; */

  font-size: 2vw;
  line-height: 3vw;
}

.description {
  font-size: 2.5vw;
  line-height: 3.5vw;
}

#statistics {
  margin-bottom: 100px;

  text-align: center;
}

/* Video */

video {
  width: 80%;
  margin: 0% 10%;
}

.play {
  position: absolute;
  display: inline-block;

  margin-top: -23%;
  margin-left: 50%;
  padding: 10px 20px;

  /* border: 1.6px solid #000;
    */
  border: 0;
  border-radius: 5px;

  background-color: transparent;
  color: #000000;

  font-size: 14px;
  text-transform: uppercase;
}

.video-cover {
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.video-cover.covered {
  opacity: 0.2;

  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

img.play-button {
  width: 20px;
}

img.pause {
  width: 20px;
  margin-left: 10px;
}

#video button {
  background-color: transparent;
}

img.pause-button {
  width: 10px;
  margin-left: 10px;

  line-height: 10px;

  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.pause {
  position: absolute;
  display: none;
  float: center;

  margin-top: -60px;
  margin-left: calc(50% - 70px);
  padding: 10px 20px;
  border: 0 solid #000000;
  border-radius: 5px;

  background-color: transparent;
  color: #000000;

  font-size: 10px;
  line-height: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Video Progress Bar */

/* Statistics */

text.stats-bold {
  font-weight: 700;
  font-size: 50px;
}

text.stats-regular {
  font-weight: 300;
  font-size: 14px;
}

#firstsLine, #membersLine, #yearsLine, #subteamsLine, #foundedLine {
  animation: dash 10s linear forwards;
  animation-delay: 2s;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* Header */

#main-header {
  display: grid;
  grid-template-columns: auto 1fr auto;

  gap: 2rem;
  align-items: center;

  max-width: 70rem;
  margin: auto;

  padding: 2rem;

  background-color: white;
}

#header-logo {
  grid-column: 1;

  width: 60px;
}

#header-navigation {
  position: relative;
  z-index: 500;
  grid-row: 1;
  grid-column: 3;

  text-align: end;
}

#header-navigation ul {
  position: absolute;
  top: 5rem;
  right: -2rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: end;

  margin: 0;
  padding: 2rem;

  background-color: white;

  list-style: none;

  opacity: 0;
  pointer-events: none;

  transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1),
    opacity 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: translateY(-100%);
}

.header-nav-link {
  padding: 0.25rem 1rem 0.1rem;

  font-weight: 700;
  font-size: 1.25rem;
  white-space: nowrap;
}

.header-nav-link a {
  color: inherit;

  text-decoration: none;

  transition: color 300ms ease-in-out;
}

.header-nav-link-active a, .header-nav-link a:hover {
  color: #e80000;

  text-decoration: none;
}

.header-nav-link-donate {
  border-radius: .25rem;

  background-color: hsl(0 70% 55%);
  color: white;
  box-shadow: 0 0 4pt hsla(0 0% 0% / 0.25);
}

.header-nav-link-donate a:hover {
  color: white;
}

.header-nav-link-apply {
  border-radius: .25rem;

  background-color: hsl(0 70% 55%);
  color: white;
  box-shadow: 0 0 4pt hsla(0 0% 0% / 0.25);
}

.header-nav-link-apply.header-nav-link-active a, .header-nav-link-apply a:hover {
  color: white;
}

#header-menu-btn {
  grid-row: 1;
  grid-column: 3;

  width: 3rem;
  height: 3rem;
  border: none;

  background-color: transparent;
  background-image: url('../static/menu.svg');
  background-position: center;
  background-size: 2rem;
  background-repeat: no-repeat;

  font-size: 0;

  cursor: pointer;
}

#header-navigation.header-navigation-open ul {
  opacity: 1;
  pointer-events: all;

  transform: translateY(0);
}

#header-navigation.header-navigation-open #header-menu-btn {
  background-image: url('../static/close.svg');
}

#countdown-menu-banner {
  margin: 0 0 0 1rem;
}

#countdown-menu-timer {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
}

#countdown-menu-title {
  margin: -0.25rem -0.5rem;
  padding: 0.25rem 0.5rem;
}

#countdown-menu-title.closed {
  background-color: hsl(0 70% 55%);
  color: white;
}

.countdown-part {
  display: grid;
  grid-template-rows: auto auto;
}

.countdown-value {
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'IBM Plex Mono', monospace;
}

@media screen and (min-width: 70rem) {
  #header-navigation ul {
    position: static;
    flex-flow: row nowrap;
    align-items: center;

    padding: 0;

    opacity: 1;
    pointer-events: all;

    transform: none;
  }

  #header-menu-btn {
    display: none;
  }
}

/* Footer */

#main-footer {
  padding-bottom: 4rem;

  /* border-top: 1px dashed hsl(0 0% 0% / 0.5); */

  background-color: hsl(0 0% 0% / 0.05);
}

#main-footer h5 {
  font-weight: 700;
}

#main-footer a {
  color: #294db1;

  text-decoration: underline;
}

/* stylelint-disable no-descending-specificity */
#main-footer ul {
  margin: 0;
  padding: 0;

  list-style: none;
}
/* stylelint-enable no-descending-specificity */

#main-footer-inner {
  display: flex;
  flex-flow: row wrap;
  gap: 2rem;

  max-width: 70rem;
  margin: auto;
  padding: 2rem;
}

.footer-about {
  min-width: min(20rem, 100%);
  max-width: 30rem;
}

.footer-site-map {
  min-width: min(15rem, 100%);
  max-width: 20rem;
}

.footer-social-media {
  min-width: min(15rem, 100%);
  max-width: 20rem;
}

.footer-social-media .social-media-item::before {
  content: ' ';
  display: inline-block;
  vertical-align: text-bottom;

  width: 0.9em;
  height: 1em;

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-inline-end: 0.5em;
}

.footer-social-media .social-media-item-fb::before {
  background-image: url('../static/social-media/facebook-color.png');
}

.footer-social-media .social-media-item-twitter::before {
  background-image: url('../static/social-media/twitter-color.png');
}

.footer-social-media .social-media-item-ig::before {
  background-image: url('../static/social-media/instagram-color.png');
}

.footer-social-media .social-media-item-linkedin::before {
  background-image: url('../static/social-media/linkedin-color.png');
}

.footer-social-media .social-media-item-youtube::before {
  background-image: url('../static/social-media/youtube-color.png');
}

/* Testing graphics */

.aloda {
  transform: scale(0.2);
}

.path {
  animation: dash 10s linear forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke-width: 2px;

  /* animation-duration: infinite;
    */
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* Advisors */

#advisors h4 {
  width: 80vw;
  margin: 0 10vw;
}

#advisors h3 {
  font-size: 20px;
}

#advisors p {
  color: #7d7d7d;

  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
}

#advisors {
  margin-top: 100px;
  margin-bottom: 100px;
}

#advisors img {
  width: 120px;
  margin: 20px;
}

#advisors i {
  position: relative;
  top: -50px;

  margin-left: -40px;

  color: #afafaf;

  font-weight: 200;
  font-style: normal;
  font-size: 16px;
}

#advisors i::before {
  content: '';
  position: absolute;
  display: inline-block;

  height: 150px;
  margin-top: 70px;
  margin-left: -10px;
  border-left: 1px solid #afafaf8c;
}

.advisor-col {
  margin: 0 2rem;
}

/* Circle Play Button */

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  background-color: #ffffff;

  -webkit-transition: height .25s ease, width .25s ease;
  transition: height .25s ease, width .25s ease;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.circle:hover {
  width: 150px;
  height: 150px;
}

.circle:before, .circle:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  border: 1px solid #ffffff;
  border-radius: 50%;
}

.circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}

.circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.circle:hover:before, .circle:hover:after {
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
  }

  75% {
    opacity: 1;

    -webkit-transform: scale(1.75);
  }

  100% {
    opacity: 0;

    -webkit-transform: scale(2);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  75% {
    opacity: 1;

    transform: scale(1.75);
  }

  100% {
    opacity: 0;

    transform: scale(2);
  }
}

/* Application */
#application label {
  display: block;
}

#application {
  position: relative;

  margin: 0% calc(20% - 20px);
  padding: 20px;
  border: 1px solid #efefef;

  background-color: #fbfbfb;
}

#application h1 {
  font-weight: 700;
  font-size: 36px;
}

#application h3 {
  padding-bottom: 20px;

  font-size: 16px;
  line-height: 26px;
}

#application label, #application h5 {
  padding-top: 10px;

  color: #616161;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

#application input[type='text'], #application input[type='tel'] {
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #616161;

  font-size: 14px;
  font-family: 'Helvetica Neue', sans-serif;
}


#application label.checkbox {
  display: inline-block;

  padding: 10px;

  color: #000000;

  font-weight: 500;
}

#application legend {
  padding-top: 10px;

  color: #616161;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;   
}

#application textarea {
  width: 60%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #616161;

  font-family: 'Helvetica Neue', sans-serif;
}

#application a.desops, #application a.hardware, #application a.software, #application a.electrical {
  display: inline-block;

  margin: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  border: 1px solid #092243;
  border-radius: 5px;

  background-color: transparent;
  color: #092243;

  font-size: 14px;
  font-family: 'Helvetica Neue', sans-serif;
  text-decoration: none;
}

/* stylelint-disable max-line-length */
#application a.desops:hover, #application a.software:hover, #application a.hardware:hover, #application a.electrical:hover {
  border: 1px solid #d73939;

  color: #d73939;
}
/* stylelint-enable max-line-length */

#application h2 {
  margin-top: 40px;

  font-weight: 700;
  font-size: 30px;
}

#application input[type='file'], #application input[type='submit'] {
  font-size: 14px;
  font-family: 'Helvetica Neue', sans-serif;
}

#application input[type='submit'] {
  margin-top: 10px;

  color: #656565;
}

#application i {
  display: inline-block;

  padding: 20px;

  color: #1326fe;

  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  font-family: 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
}

#application label i {
  display: block;

  color: #848484;

  font-weight: 500;
  font-style: italic;
  font-size: 12px;
}

#application b {
  color: #d73939;

  font-size: 18px;
  line-height: 18px;
}

#application label b {
  display: block;

  padding: 20px;
  border-left: 1px solid #aaaaaa;

  color: #212121;

  font-weight: 300;
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
}

@media (max-width: 1100px) {
  #application {
    margin-top: 200px;
    margin-bottom: 20px;
  }

  html {
    overflow-x: hidden;
  }

  .name {
    margin-left: 110px;

    font-size: 6.5vw;
    line-height: 6.5vw;
  }

  .description {
    margin-left: 110px;

    font-size: 4vw;
    line-height: 4vw;
  }

  video {
    width: 100vw;
    margin: 0;
  }

  .play {
    position: relative;
    bottom: calc((.56*100vw) / 2 - 35px);
  }

  #map {
    height: 55vh;
    margin-top: 0;
  }

  #countdown i {
    margin-left: -20px;
  }

  #countdown {
    right: auto;

    margin-top: 0;
    margin-left: 20vw;

    -ms-transform: scale(1.2, 1.2);

    /* IE 9 */
    -webkit-transform: scale(1.2, 1.2);

    /* Safari */
    transform: scale(1.2, 1.2);
  }
}

/* Responsive - Mobile */

@media only screen and (max-width: 600px) {

  /* Main */
  #countdown i {
    margin-left: -20px;
  }

  #countdown {
    right: auto;

    margin-top: 0;
    margin-left: 20vw;

    -ms-transform: scale(1.3, 1.3);

    /* IE 9 */
    -webkit-transform: scale(1.3, 1.3);

    /* Safari */
    transform: scale(1.3, 1.3);
  }

  /* Application */
  #application {
    margin-top: 200px;
    margin-bottom: 20px;
  }

  #application textarea {
    width: 100%;
  }

  /* Subteams */
  .side-project {
    width: 100%;
  }

  .name {
    width: 50vw;
    margin-left: 60px;

    font-size: 9vw;
    line-height: 9vw;
  }

  .description {
    margin-left: 60px;
    padding-right: 20%;

    font-size: 4.5vw;
    line-height: 5vw;
  }

  #header {
    height: 0;
  }

  #statistics svg {
    margin: 0;
    margin-left: -200px;

    -ms-transform: scale(.5, .5);

    /* IE 9 */
    -webkit-transform: scale(.5, .5);

    /* Safari */
    transform: scale(.5, .5);
  }

  #advisors {
    margin-top: 2rem;
    margin-left: 1rem,
  }

  #event-title h1 {
    background-color: transparent;

    line-height: inherit;
  }
}

/*All banners */
.banner {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  width: 100%;
  
  margin: auto;
  padding: 0.75rem;

  background-color: rgb(12, 29, 101);
  color: white;
  box-shadow: 0 0 4pt hsla(0 0% 0% / 0.25);

  text-align: center;
}


.banner-text {
  margin: 0;

  font-weight: 300;
  font-size: 12pt;
}

.banner-link {
  color: rgb(255, 255, 255);

  font-weight: 600;
  text-decoration: underline;
}
